<template>
  <div class="min-h-570">支付完成</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.inspect();
  },
  methods: {
    inspect() {
      let query = this.$route.query;
      let data = {
        orderNumber: query.orderNumber,
      };
      this.Api.Pay.payResult(data).then((res) => {
        if (res.status == 200) {
          let payInfo = res.data;
          let payTitle = payInfo.state == 1 ? "支付成功" : "支付失败";
          let payType = payInfo.state == 1 ? "success" : "warning";
          this.$confirm(payTitle, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: payType,
          })
            .then(() => {
              if (payInfo.state == 1) {
                this.$router.push({
                  path: "/player",
                  query: {
                    vid: null,
                    cid: payInfo.cid,
                  },
                });
                this.$message.success("去详情");
              } else {
                this.$route.go(-1);
              }
            })
            .catch(() => {
              this.$route.go(-1);
            });
        }
      });
    },
  },
};
</script>